const MENU_DATA = [
  {
    path: 'banner',
    label: 'Home',
  },
  {
    path: 'services',
    label: 'Services',
  },
  {
    path: 'roadmap',
    label: 'Roadmap',
  },
  {
    path: 'downloads',
    label: 'Downloads',
  },
  {
    path: 'contact',
    label: 'Contact',
  },
];
export default MENU_DATA;
